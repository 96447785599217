<template>
    <div>
        <h2>Хабарламалар</h2>
        <br>
        <v-card class="mx-auto my-3">
           <v-row align="center">
               <v-col lg="1">
                <v-img class="card-img" src="../../assets/warning.png"></v-img>
               </v-col>
               <v-col lg="9">
                   <v-card-title>
                       Хабарлама
                   </v-card-title>
                   <v-card-text>
                       Ребенок ҒАНИ И.Қ. зачислен в дошкольную организацию КГКП Ясли-сад №2 "Ак сункар", группа «Шамшырақ»* (от 2 до 3 лет) по направлению №794342. Отдел образования г.Атырау. GR1
                   </v-card-text>
               </v-col>
               <v-col lg="2">
                   <v-card-subtitle>
                        12.12.2019 
                   </v-card-subtitle>
               </v-col>
           </v-row>
        </v-card>
        <v-card class="mx-auto my-3">
           <v-row align="center">
               <v-col lg="1">
                <v-img class="card-img" src="../../assets/warning.png"></v-img>
               </v-col>
               <v-col lg="9">
                   <v-card-title>
                       Хабарлама
                   </v-card-title>
                   <v-card-text>
                       Ребенок ҒАНИ И.Қ. зачислен в дошкольную организацию КГКП Ясли-сад №2 "Ак сункар", группа «Шамшырақ»* (от 2 до 3 лет) по направлению №794342. Отдел образования г.Атырау. GR1
                   </v-card-text>
               </v-col>
               <v-col lg="2">
                   <v-card-subtitle>
                        12.12.2019 
                   </v-card-subtitle>
               </v-col>
           </v-row>
        </v-card>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
h2{
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #203D89;
}
.card-img{
    width: 50px;
    left: 30%;
}
</style>